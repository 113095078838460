.auth-logo {
  width: 200px;
}

.Toastify__toast-body {
  padding-left: 1em;
  padding-right: 1em;
  font-size: 0.9em;
}

.table-component {
  .table-header {
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    &-show {
      padding-top: 0.25em;
      padding-bottom: 0.25em;
    }
    &-search {
      padding-top: 0.25em;
      padding-bottom: 0.25em;
    }

    @include media-breakpoint-up(sm) {
      &-show {
        width: 120px;
      }
      &-search {
        width: 300px;
        float: right;
      }
    }
  }
  .table-footer {
    border-top: 1px solid $gray-300;

    @include media-breakpoint-down(md) {
      text-align: center;
      .pagination {
        justify-content: center !important;
      }
    }
  }
}
.badge {
  font-size: 80%;
}

.billing-detail-date-header {
  @include media-breakpoint-up(lg) {
    max-width: 300px;
    float: right;
  }
  th,
  td {
    padding: 0.25em;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.form-input-react-dates {
  .SingleDatePicker {
    display: block;
  }
  .SingleDatePickerInput {
    display: block;
  }
  .DateInput {
    width: 100%;
  }
}

.bg-white-transparent {
  background-color: #f7fafcb0 !important;
}

#receipt-image {
  width: 100%;
}

.overflow-bar {
  overflow-x: auto !important;
  width: 1030px !important;
}

.overflow-pie {
  overflow-x: auto !important;
  width: 530px !important;
}

.form-control-white {
  background-color: #fff !important;
}
